import { GetOutletsAroundMe } from '@/services/order.api';
import { toast } from 'react-toastify';
import { updateUserLocation } from '@/redux/slices/userLocation.slice';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const getRelatedProducts = (
  menu: any,
  category: string[],
  excludeIds: string[]
) => {
  const relatedProducts = menu.filter(
    (product: any) =>
      category.includes(product.category) && !excludeIds.includes(product.id)
  );
  return relatedProducts;
};

const pickRandomRelatedItems = (cartItem: any) => {
  const maxRelatedItems = 3;

  const relatedProducts = getRelatedProducts([], [], [cartItem.id]);
  const selectedRelatedItems = [];

  while (
    selectedRelatedItems.length < maxRelatedItems &&
    relatedProducts.length > 0
  ) {
    const randomIndex = getRandomInt(relatedProducts.length);
    selectedRelatedItems.push(relatedProducts[randomIndex]);
    relatedProducts.splice(randomIndex, 1);
  }

  return selectedRelatedItems;
};

const processCartItems = (cartItems: any) => {
  const cartItemsWithRelatedItems = cartItems.map((cartItem: any) => {
    const relatedItems = pickRandomRelatedItems(cartItem);
    return {
      cartItem,
      relatedItems,
    };
  });

  return cartItemsWithRelatedItems;
};

export const truncateText = (string: string, len: number) => {
  if (string.length > len) {
    return string.slice(0, len) + '...';
  }
  return string;
};

export const shuffleArray = (array: any[]) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

export const readableDate = (date: Date): string =>
  new Date(date).toISOString().substring(0, 10);

export const getErrorMessge = (error: any) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();

  return message;
};

export const flashError = (error: any) => {
  console.error(error);
  const errorMsg = getErrorMessge(error);
  toast.error(errorMsg);
};

export const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isDeliveryTime = (): boolean => {
  const currentHour = Date.now();

  const startTime = new Date().setHours(0, 0, 0, 0);
  const endTime = new Date().setHours(23, 0, 0, 0);

  return currentHour >= startTime && currentHour <= endTime;
};

export const calcuateServiceFee = (amount: number) => {
  let serviceFee: number;
  const percentageFee = Math.round((amount / 100) * 1.5);
  if (amount < 2500) {
    serviceFee = percentageFee;
  } else if (percentageFee > 2000) {
    serviceFee = 2000;
  } else {
    serviceFee = percentageFee + 100;
  }
  return serviceFee;
};

// Get user location
export async function getUserLocation(
  input: HTMLInputElement | null,
  dispatch?: Dispatch<AnyAction>
) {
  // Check if Google Maps API is available
  if (typeof google === 'undefined' || !google.maps || !google.maps.places) {
    console.error('Google Maps API not loaded');
    return;
  }

  if (input) {
    try {
      // Define the geographical bounds for Nigeria>
      const nigeriaBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng({ lat: 6.388274, lng: 2.714183 }),
        new google.maps.LatLng({ lat: 13.707279, lng: 13.632359 })
      );

      // Initialize Google Places Autocomplete with the input element
      const autoComplete = new google.maps.places.Autocomplete(input, {
        bounds: nigeriaBounds,
        fields: ['formatted_address', 'geometry', 'name'],
        componentRestrictions: { country: 'ng' },
      });

      autoComplete.addListener('place_changed', async () => {
        const place = autoComplete.getPlace();
        // console.log("===> user's selected location: ", place);
        const userLocation = {
          address: place.formatted_address,
          street: '',
          city: '',
          lat: place.geometry?.location?.lat(),
          lng: place.geometry?.location?.lng(),
          name: place.name,
        };
        AppStorage.save('location', JSON.stringify(userLocation));
        dispatch?.(updateUserLocation(userLocation));

        if (userLocation.lat && userLocation.lng) {
          const res = await GetOutletsAroundMe(
            userLocation.lat,
            userLocation.lng
          );
          AppStorage.save('outlets', JSON.stringify(res));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export function mapItemsByCategory(items: any) {
  const categoryOrder = [
    'all',
    'food',
    'protein',
    'pastry',
    'cakes',
    'shawarma',
    'bread',
    'ice cream',
    'drinks',
    'promo',
  ];
  const categorizedItems: any = {};

  // Initialize all categories in the specified order
  categoryOrder.forEach((category) => {
    categorizedItems[category] = [];
  });

  items.forEach((item: any) => {
    const category = item.category.toLowerCase(); // Normalize for consistency

    // Ensure the category exists in the object and add the item
    if (categorizedItems[category]) {
      categorizedItems[category].push(item);
    }
  });

  // Populate the "all" category in the specified order
  categoryOrder.forEach((category) => {
    if (category !== 'all') {
      categorizedItems.all.push(...categorizedItems[category]);
    }
  });
  return categorizedItems;
}

const AppStorage = {
  save: (key: string, value: any) => {
    try {
      if (typeof window === 'undefined') {
        return;
      }
      localStorage.setItem(key, value);
    } catch (error) {
      console.error('Unable to save to localStorage: ', error);
    }
  },
  get: <T = any>(key: string): T | null => {
    if (typeof window === 'undefined') {
      return null;
    }
    const str = localStorage.getItem(key);

    return str as any;
  },
  remove: (key: string) => {
    if (typeof window === 'undefined') {
      return;
    }
    localStorage.removeItem(key);
  },
  clear: () => {
    if (typeof window === 'undefined') {
      return;
    }
    localStorage.clear();
  },
};

export const formatDate = (timestamp: string) => {
  return new Date(timestamp).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export default AppStorage;

export function getInitialsAndColor(name: string) {
  // Split the name by space and take the first letter of each word
  const initials = name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('');

  // Function to generate a random hex color
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  // Ensure the color is always 6 characters long
  const paddedColor =
    randomColor.length === 7 ? randomColor : randomColor + '0';

  // Return the initials and the random color
  return {
    initials,
    color: paddedColor,
  };
}
